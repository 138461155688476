.container {
  padding: 0 20px;
  & .contents{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 80px auto;
  }
  .message {
    font-size: 1.6rem;
    color: #333;
    margin-bottom: 1.5rem;
    line-height: 1.5;
    font-family: "Inter";
    @media (max-width: 767px) {
        font-size: 18px;
        line-height: 22px;
    }
  }

  .button {
    background-color: #f26915;
    font-family: "Inter";
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    padding: 0.8rem 1.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 0.5rem 0;
    transition: background-color 0.3s ease, transform 0.2s ease;
    @media (max-width: 767px) {
        padding: 0.6rem 1.5rem;
    }
  }

  .button:hover {
    opacity: 0.9;
  }

  .contactButton {
    background-color: #28a745;
  }
}
