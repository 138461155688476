@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  position: relative;
  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
  & .favoriteButton {
    position: absolute;
    z-index: 1;
    top: 12px;
    right: 12px;
    min-height: 31px;
    height: 31px;
    width: 31px;
    width: auto;
    background: #fff;
    border-radius: 100px;
    min-width: 31px;
    &:hover {
      box-shadow: unset;
    }
  }
  & .notActiveFavorite {
    & svg {
      & path {
        fill: gainsboro;
      }
    }
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100); /* Loading BG color */
  border-radius: var(--borderRadiusMedium);

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
  width: 100%;
  height: 100%;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 14px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 4px;
}

.priceValue {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightBold);
  color: var(--colorGrey700);
  margin: 0;
  font-size: 18px;
  line-height: 24px;
  @media (max-width: 560px) {
    font-size: 14px;
    line-height: 18px;
    
  }
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey700);
display: flex;
align-items: center;
flex-wrap: wrap;
gap: 4px;
  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 20px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
.promotionGrid {
  &>div {
    &:nth-child(1) {
    /* height: 341px; */
    background-color: #fff;
   @media(max-width:560px) {
    /* height: 260px; */
   }
    &:hover {
      transform: none;
      box-shadow: none;
    }
    &>div {
      position: relative;
      padding-bottom: 56.25% !important;
      &>div {
        & img {
          /* height: 341px; */
          border-radius: 8px;
          border: 0;
          object-fit: cover;

          @media(max-width:560px) {
            /* height: 260px; */
           }
        }
      }
    }
  }

  &:nth-child(2) {
    /* padding-top: 32px; */
    &>div {
      &:nth-child(2) {
        &>div {
          &:nth-child(1) {
            font-size: 24px;
            font-family: "Inter";
            line-height: normal;
          }
          &:nth-child(2) {
            font-size: 18px;
            font-family: "Inter";
            color: #828282;
            line-height: 30px;
          }
        }
      }
    }
  }
  }
}